import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const PinIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    overflow="inherit"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="M610.613412,472.509907 C633.859999,427.885295 647,377.16074 647,323.368421 C647,144.776974 502.164117,0 323.5,0 C144.835883,0 0,144.776974 0,323.368421 C0,378.668514 13.8869407,430.726458 38.3651157,476.247885 L320.535726,1021.66685 L610.729985,472.509868 L610.613432,472.509868 L610.613412,472.509907 Z M323.5,431.157895 C383.054706,431.157895 431.333333,382.898904 431.333333,323.368421 C431.333333,263.837939 383.054706,215.578947 323.5,215.578947 C263.945294,215.578947 215.666667,263.837939 215.666667,323.368421 C215.666667,382.898904 263.945294,431.157895 323.5,431.157895 Z" />
      </g>
    </g>
  </Svg>
);

export default PinIcon;
