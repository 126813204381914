import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const HeartEmptyIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    overflow="inherit"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <g>
      <path
        fill={color}
        d="M 88.421875 167.628906 C 184.824219 71.226562 338.988281 67.78125 439.515625 157.300781 L 450.460938 167.628906 L 510.800781 227.96875 L 571.140625 167.628906 L 582.082031 157.300781 C 682.613281 67.78125 836.773438 71.226562 933.179688 167.628906 C 1026.203125 260.652344 1032.976562 408.160156 951.796875 508.945312 L 942.039062 520.359375 L 931.71875 531.164062 L 510.765625 952.046875 L 88.421875 529.667969 L 78.132812 518.824219 L 68.527344 507.570312 C -11.378906 408.160156 -4.601562 260.652344 88.421875 167.628906 Z M 148.761719 227.96875 C 90.449219 286.28125 82.546875 377.652344 125.753906 441.75 L 133.429688 452.167969 L 140.03125 460.085938 L 148.761719 469.332031 L 510.753906 831.371094 L 870.019531 472.214844 L 878.808594 463.054688 C 939.695312 395.878906 937.15625 292.285156 872.839844 227.96875 C 812.039062 167.167969 716.238281 161.601562 650.734375 211.085938 L 640.65625 219.355469 L 631.480469 227.96875 L 510.800781 348.648438 L 390.121094 227.96875 C 323.46875 161.320312 215.410156 161.320312 148.761719 227.96875 Z M 148.761719 227.96875 "
      />
    </g>
  </Svg>
);

export default HeartEmptyIcon;
