import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const ShoppingBagIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    overflow="inherit"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <g fill={color}>
      <path
        d="M875.3,203.9H685.7v-93.3C685.7,57,647,0,575.1,0H451c-71.9,0-110.6,57-110.6,110.6V204H150.7
		C110,204,77,237,77,277.7c0,0,0,0,0,0v672.6c0,40.7,33,73.7,73.7,73.7h724.6c40.7,0,73.7-33,73.7-73.6c0,0,0,0,0,0V277.7
		C949,237,916.1,204,875.3,203.9C875.3,203.9,875.3,203.9,875.3,203.9z M426.7,110.6c0-4.1,1.2-24.3,24.3-24.3h124.1
		c23.1,0,24.3,20.2,24.3,24.3V204H426.7V110.6z M862.7,937.7H163.4V290.3h177v77.4c-0.4,23.8,18.6,43.5,42.5,43.9
		c23.8,0.4,43.5-18.6,43.9-42.5c0-0.5,0-0.9,0-1.4v-77.4h172.7v77.4c-0.4,23.8,18.6,43.5,42.5,43.9c23.8,0.4,43.5-18.6,43.9-42.5
		c0-0.5,0-0.9,0-1.4v-77.4h176.9L862.7,937.7z"
      />
    </g>
  </Svg>
);

export default ShoppingBagIcon;
