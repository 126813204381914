import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import type {
  Configuration,
  Country,
  FacetDisplayDataList,
  Maybe,
  Navigation,
  SocialMediaChannel,
} from '../../graphql/codegen';
import type { GlobalAccessibilityLabels as GlobalAccessibilityLabelsSchema } from '../../../amplienceTypes/schemas/imported/global-accessibility-labels-schema';
import { PageTypes } from '../../constants';
import { useAppContext } from './AppContext';
import type { GlobalNotifications } from '../../../amplienceTypes/schemas/imported/global-notifications-schema';
import type { GlobalFormErrorLabels } from '../../../amplienceTypes/schemas/imported/global-form-error-labels-schema';
import type { GlobalQuickViewType } from '../../../types/amplience';
import { formatNumberByMask } from '../../helpers';

type StaticContextPropType = {
  configuration: Configuration;
  leftNavigation: Navigation;
  socialMediaChannels: SocialMediaChannel[];
  quickViewContent?: GlobalQuickViewType;
  flyoutNavigation: Navigation;
  outletFlyoutNavigation: Navigation;
  inpageNavigation?: Navigation;
  countryEn?: Country;
  accessibilityLabels?: GlobalAccessibilityLabelsSchema;
  pageType: PageTypes;
  formErrorLabels?: GlobalFormErrorLabels;
  notifications?: GlobalNotifications;
};

type StaticContextStateType = {
  facetDisplayDataListMap: Map<Maybe<string> | undefined, Maybe<FacetDisplayDataList>>;
  priceFormatter: (value: number) => string;
};

const StaticContext = createContext<StaticContextPropType & StaticContextStateType>({
  configuration: {},
  leftNavigation: {},
  socialMediaChannels: [],
  quickViewContent: undefined,
  flyoutNavigation: {},
  outletFlyoutNavigation: {},
  inpageNavigation: undefined,
  countryEn: undefined,
  accessibilityLabels: undefined,
  formErrorLabels: undefined,
  facetDisplayDataListMap: new Map(),
  pageType: PageTypes.OTHER,
  notifications: undefined,
  priceFormatter: () => '',
});

export const useStaticContext = () => useContext(StaticContext);

export const StaticContextProvider = ({
  configuration,
  leftNavigation,
  socialMediaChannels,
  quickViewContent,
  flyoutNavigation,
  outletFlyoutNavigation,
  inpageNavigation,
  countryEn,
  accessibilityLabels,
  formErrorLabels,
  notifications,
  children,
  pageType,
}: PropsWithChildren<StaticContextPropType>) => {
  const { pushToAppSettings } = useAppContext();
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const date = now.getDate().toString().padStart(2, '0');
  const hours = now.getHours().toString().padStart(2, '0');

  const hourPreciseCurrentDate = new Date(`${year}-${month}-${date} ${hours}:00:00`);

  const { currentCurrencySymbol, currentCurrencyNumberFormat } = configuration;

  const priceFormatter = useCallback(
    (priceVal: number) =>
      `${currentCurrencySymbol} ${formatNumberByMask(
        priceVal,
        currentCurrencyNumberFormat || '#,##0.00'
      )}`,
    [currentCurrencySymbol, currentCurrencyNumberFormat]
  );

  const value = useRef({
    configuration: {
      ...configuration,
      staticFiltersQuery: configuration.staticFiltersQuery?.replaceAll(
        '{currentDate}',
        hourPreciseCurrentDate.getTime().toString()
      ),
    },
    facetDisplayDataListMap: new Map(
      configuration.facetDisplayDataList?.map(facet => [facet?.code, facet])
    ),
    leftNavigation,
    socialMediaChannels,
    quickViewContent,
    flyoutNavigation,
    outletFlyoutNavigation,
    inpageNavigation,
    countryEn,
    accessibilityLabels,
    formErrorLabels,
    pageType,
    notifications,
    priceFormatter,
  });

  useEffect(() => {
    pushToAppSettings({ pageType: pageType?.toUpperCase() });
  }, [pushToAppSettings, pageType]);

  return <StaticContext.Provider value={value.current}>{children}</StaticContext.Provider>;
};
