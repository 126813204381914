import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const LoginIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    overflow="inherit"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <path
      fill={color}
      d="M511.551,0C366.683,0,256,110.646,256,256.539C256,401.354,366.683,512,511.551,512C657.617,512,768,401.354,768,256.539
	C768,110.646,657.617,0,511.551,0"
    />
    <path
      fill={color}
      d="M928,750.628V1024H96V750.628v-12.75C96,663.048,168.984,576,258.971,576H765.25C855.058,576,928,663.048,928,737.878
	V750.628z"
    />
  </Svg>
);

export default LoginIcon;
